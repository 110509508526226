
import { Component, Vue, Watch } from 'vue-property-decorator';
import { serverService } from '@/services/serverService';
import GameInfor from '@/game/GameInfor.vue';
import AreaTitle from '@/components/AreaTitle.vue';
import GameBadges from '@/game/GameBadges.vue';
import { showSideAds } from '@/filters/mobile';
import AdsResponsive from '@/components/ads/AdsResponsive.vue';
import { translate } from '@/filters/translate';
import { IClient } from '@/vo/Client';
import { IGameBadgeWithUser } from '@/vo/Badge';
import { Route } from 'vue-router';
import { errorToString } from '@/filters/content-filters';
import { dialogService } from '@/services/dialogService';
import { getClientForumRoute } from '@/filters/game-filters';
import { timestampToTimeAgo } from '@/filters/timestampToString';
import { numberWithCommas } from '@/filters/number-filters';
import { DomUtil } from '@/utils/DomUtil';

declare const $: any;
declare const bootstrap: any;

@Component({
    components: {
        GameInfor,
        GameBadges,
        AdsResponsive,
        AreaTitle,
    },
    methods: {
        translate,
        showSideAds,
        getClientForumRoute,
        timestampToTimeAgo,
        numberWithCommas,
    },
})
export default class GameBadgePage extends Vue {
    client: IClient | null = null;
    gameBadge: IGameBadgeWithUser | null = null;
    awardPercent = 0;
    rarityClz = '';

    @Watch('$route', { immediate: true })
    onRouteChanged(to: Route): void {
        this.load(to.params['clientCode'], to.params['badgeCode']);
    }

    load(clientCode: string, badgeCode: string): void {
        this.client = null;
        this.gameBadge = null;
        serverService
            .getInitData()
            .then(() => serverService.client.getGameBadge(clientCode, badgeCode))
            .then((data) => {
                this.client = data.client;
                this.gameBadge = data.gameBadge;
                this.awardPercent = Math.max(0, Math.min(100, (data.gameBadge.aquired / data.totalPpl) * 100));
                if (this.awardPercent < 10 || this.awardPercent >= 90) {
                    this.awardPercent = Math.round(this.awardPercent * 10) / 10;
                } else {
                    this.awardPercent = Math.round(this.awardPercent);
                }
                this.rarityClz = getRarityColorClz(this.awardPercent);
                document.title = `${this.gameBadge.name} (${translate('gameBadge.srcGame')}:${this.client.name})`;
                DomUtil.enableRarityTooltip(this);
            })
            .catch((err) => {
                dialogService.error({ body: errorToString(err) });
            });
    }

    get loading(): boolean {
        return !this.client;
    }
}
function getRarityColorClz(percentage: number) {
    if (percentage >= 60) {
        return 'common';
    }
    if (percentage >= 40) {
        return 'uncommon';
    }
    if (percentage >= 20) {
        return 'rare';
    }
    if (percentage >= 5) {
        return 'epic';
    }
    return 'legendary';
}
