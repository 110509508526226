
import { serverService } from '@/services/serverService';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import GoogleSearch from '@/components/GoogleSearch.vue';
import { translate } from '@/filters/translate';

declare const $: any;
type SearchControl = { execute: (q: string) => void; destroyed: boolean };

@Component({
    components: {
        GoogleSearch,
    },
})
export default class GoogleSearchResult extends Vue {
    public $refs!: Vue['$refs'] & {
        root: HTMLDivElement;
    };

    searchTerm = '';

    searchControl?: SearchControl;

    onChange(term: string): void {
        this.renderResult(term);
    }

    mounted(): void {
        getGoogleSearchElement(this).then((control) => {
            this.searchControl = control;
        });
        document.title = translate('localedTitle') + ':' + translate('googleSearch.result');
    }
    destroyed(): void {
        this.searchControl && (this.searchControl.destroyed = true);
    }

    @Watch('$route', { immediate: true, deep: true })
    onRouteChange(route: Route): void {
        let term = route.query.q as string;
        this.renderResult(term);
    }

    private renderResult(term: string): void {
        this.searchTerm = term;
        if (this.searchControl) {
            this.searchControl.execute(term);
        } else {
            setTimeout(() => this.renderResult(term), 30);
        }
    }
}

let searchControl: SearchControl;

function getGoogleSearchElement(comp: GoogleSearchResult, tries = 0): Promise<SearchControl> {
    if (searchControl && !searchControl.destroyed) {
        return Promise.resolve(searchControl);
    } else {
        let google: any = window['google'];
        let element: any = google && google.search.cse.element;
        if (element) {
            element.go(comp.$refs.root);
            searchControl = element.getElement('searchResult');
            return Promise.resolve(searchControl);
        } else if (tries < 10) {
            return serverService.wait(30).then(() => getGoogleSearchElement(comp, tries + 1));
        } else {
            return Promise.reject('timeout');
        }
    }
}
