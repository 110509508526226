var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('router-link',{class:{
        rateStars: 1,
        noLink: !_vm.client,
        simple: _vm.simple,
    },style:({ fontSize: _vm.size + 'px' }),attrs:{"to":_vm.toRoute}},[_c('div',{class:{
            stars: 1,
            interactive: _vm.interactive,
            choosing: _vm.displayRate != _vm.rate,
        },attrs:{"title":_vm.interactive ? '' : _vm.starsTip},on:{"pointerleave":function($event){return _vm.onPointerLeave()},"click":function($event){_vm.interactive && _vm.emitSelect()}}},[(!_vm.simple)?_c('div',[_vm._l((_vm.stars),function(star,index){return _c('i',{key:index,class:star,on:{"pointerenter":function($event){return _vm.onPointerEnter(index + 1)}}})}),(_vm.votes)?_c('span',{staticClass:"votes"},[_vm._v("("+_vm._s(_vm.numberWithCommas(_vm.votes))+")")]):_vm._e()],2):(_vm.rate != 0)?_c('div',{staticClass:"star-bar"},[_c('span',[_vm._v(_vm._s(_vm.rate.toFixed(1)))]),_c('i',{staticClass:"fas fa-star simple-star"})]):_vm._e()]),(_vm.showTips)?_c('div',{staticClass:"rate-tips"},[_vm._v(" "+_vm._s(_vm.translate('review.message.rate_' + _vm.displayRate))+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }