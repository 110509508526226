import { importTranslation } from '../translate';

importTranslation('en-US', {
    game: {
        recommendGames: 'Recommended Games',
        allGames: 'All Games',
        betaGames: 'Beta Games',
        onlinePpl: '{ppl} ppl online',
        trailer: 'Game Trailer',
        trailer2: 'Trailer',
        seeMore: {
            title: 'More Games!',
            subtitle: 'View games posted by other players',
        },
        btn: {
            play: 'Play',
            report: 'Game Support',
            forum: 'Game Discuss',
            viewClientGM: 'View Game Managers',
            news: 'News & Updates',
            review: 'Reviews',
        },
        forum: {
            title: 'Discuss the game',
            officialTitle: 'Discussion',
        },
        report: {
            title: 'Game Support',
            officialTitle: 'Support',
        },
        contribute: {
            title: 'Contribute Article',
            officialTitle: 'Article',
        },
        review: {
            title: 'Game Reviews',
            officialTitle: 'Review',
        },
        manager: {
            title: 'Manager List',
            nobody: 'There is no manager for this game.',
        },
        news: {
            title: 'News & Updates',
            officialTitle: 'Announcements',
        },
        order: {
            new: 'New Games',
            rate: 'Top Rated',
            ppl: 'Online Players',
        },
        betaMessages: [
            'Welcome to the Games in Beta zone，here is the game center that has not yet completed the test.',
            'Game in Gamelet needs to have enough player reviews to be officially launched. If you find a game worth mentioning, please write it a review so that more players can see these games.',
            'Click a game on this page and then click the stars to go to the review page. You will need to play a game for {min} minutes for more to write it a review.',
        ],
        sysTitle: {
            btn: {
                toggleSys: 'To Event',
                toggleGame: 'To Game',
            },
            eventsTitle: 'Contest Events',
            forumTitle: 'Discuss',
            newsTitle: 'News',
            labelOrganizer: 'Organizer',
            labelMembers: 'Organizer',
            title: 'User Title Settings',
            gameName: 'Event Name',
            gameTime: 'Event Date',
            gameDesc: 'Event Desc',
            gamePpls: 'Entries',
            addItem: 'Add Honor Title',
            itemName: 'Honor Title',
            addUserItem: 'Add User',
            gameResult: 'Result',
            addVideo: 'Add Video',
            videoUrl: 'Youtube URL',
            status: {
                pub: 'Public',
                hidden: 'Hidden',
            },
        },
    },
    gameBadges: {
        title: 'Game Badges',
        owned: 'Owned badge',
        missing: 'Missing badge',
    },
    gameBadge: {
        srcGame: 'Source:',
        timeGet: 'You got this badge {firstTime}',
        firstGet: 'You first got this badge {firstTime}',
        lastGet: 'As of {lastTime}, a total of {count} times',
        awardPercent: 'About {percentage}% players own this badge',
        notOwned: 'You have not awarded this badge yet',
        rarity: {
            common: 'Common',
            uncommon: 'Uncommon',
            rare: 'Rare',
            epic: 'Epic',
            legendary: 'Legendary',
        },
    },
});

export const game_translation_en = 1;
