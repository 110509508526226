var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.isMobile())?_c('div',{class:{
        'appBar-root': true,
        appBar: true,
        shrink: _vm.shrink,
        noShadow: _vm.noShadow,
    }},[_c('div',{staticClass:"logoAndMenu"},[_c('router-link',{staticClass:"gltMark",attrs:{"to":{ name: 'home' }}},[_c('img',{staticClass:"gltIcon",attrs:{"draggable":"false","src":require("../assets/gamelet_icon.png")}}),_c('div',{staticClass:"gltTitleTexts"},[_c('img',{staticClass:"gltIconText",class:{ subTitle: !!_vm.translate('localedTitle') },attrs:{"draggable":"false","src":require("../assets/gamelet_text.png")}}),_c('div',{class:{
                        gltTitle: 1,
                        subTitle: !_vm.translate('localedTitle'),
                    }},[_vm._v(" "+_vm._s(_vm.translate('localedTitle'))+" ")])])]),_c('div',{staticClass:"menu"},[_c('div',{ref:"games",staticClass:"menuTab games"},[_c('router-link',{attrs:{"to":{ name: 'games' }}},[_vm._v(_vm._s(_vm.translate('links.games')))])],1),_c('div',{ref:"forums",staticClass:"menuTab forums"},[_c('router-link',{attrs:{"to":{ name: 'forums' }}},[_vm._v(_vm._s(_vm.translate('links.forums')))])],1),_c('div',{ref:"deposit",staticClass:"menuTab deposit"},[_c('router-link',{attrs:{"to":{ name: 'deposit' }}},[_vm._v(_vm._s(_vm.translate('links.recharge')))]),_c('div',{staticClass:"new"},[_vm._v("新")])],1),_c('div',{ref:"blog",staticClass:"menuTab blog"},[_c('router-link',{attrs:{"to":{ name: 'blog' }}},[_vm._v(_vm._s(_vm.translate('links.gameletBlog.tab')))])],1),_c('div',{ref:"coding",staticClass:"menuTab coding"},[_c('router-link',{attrs:{"to":{ name: 'coding' }}},[_vm._v(_vm._s(_vm.translate('links.codeGamelet.tab')))])],1),_c('div',{staticClass:"activeBar",style:({ left: _vm.activeBarX })})])],1),_c('div',{staticClass:"spacer"}),_c('div',{staticClass:"topright"},[_c('ThemeBtn',{staticClass:"theme"}),_c('NotiBell',{staticClass:"bell"}),_c('GoogleSearch',{staticClass:"searchBar"}),_c('UserManage',{staticClass:"userManage"})],1)]):_c('div',{class:{
        'mobile-appBar-root': true,
        appBar: true,
        noShadow: _vm.noShadow,
    }},[_c('router-link',{staticClass:"gltMark",attrs:{"to":{ name: 'home' }}},[_c('img',{staticClass:"gltIcon",attrs:{"draggable":"false","src":require("../assets/gamelet_icon.png")}}),_c('div',{staticClass:"gltTitleTexts"},[(!_vm.translate('localedTitle'))?_c('img',{staticClass:"gltIconText",attrs:{"draggable":"false","src":require("../assets/gamelet_text.png")}}):_c('div',{staticClass:"gltTitle"},[_vm._v(" "+_vm._s(_vm.translate('localedTitle'))+" ")])])]),_c('div',{staticClass:"spacer"}),_c('ThemeBtn',{staticClass:"theme"}),_c('NotiBell',{staticClass:"bell"}),_c('button',{staticClass:"navbar-toggler",on:{"click":function($event){return _vm.controlRightNavbar(!_vm.openRightNavbar)}}},[_c('i',{staticClass:"fas fa-bars"}),(_vm.openRightNavbar)?_c('i',{staticClass:"fas fa-arrow-right hide-rightNavbar-icon"}):_vm._e()]),_c('RightNavbar',{attrs:{"isOpen":_vm.openRightNavbar},on:{"toggleNavBar":_vm.controlRightNavbar}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }