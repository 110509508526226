
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { timestampToTimeAgo } from '@/filters/timestampToString';
import { DISCUSS_CATEGORY } from '@/services/discussService';
import { clientCodeToName } from '@/filters/game-filters';
import { translate } from '@/filters/translate';
import { serverService } from '@/services/serverService';
import { INotification, IPushNotiData } from '@/vo/Noti';

@Component({
    methods: {
        timestampToTimeAgo,
        translate,
    },
})
export default class PushNotiBox extends Vue {
    @Prop()
    notification?: INotification;

    @Prop()
    data!: IPushNotiData;

    @Prop({ default: true })
    shadow!: boolean;

    fadeout = false;

    @Watch('notification')
    onNotiChange(): void {
        this.fadeout = false;
    }

    getRoute(): string {
        let url = this.data.link;
        let search = url.match(/^(https?:\/\/[^\/]+)?(\/gltserver)?(\/.*)$/);
        url = search ? search[3] : url;
        return url;
    }

    getClaz(): string[] {
        let list = ['noti-box', this.data.category];
        if (this.shadow) {
            list.push('shadow');
        }
        if (this.fadeout) {
            list.push('fadeout');
        }
        return list;
    }

    get tag(): string {
        let name = clientCodeToName(this.data.subKey, this.data.category);
        return name || translate('forum.label.track.' + this.data.category);
    }

    markRead(event: MouseEvent): void {
        event.stopImmediatePropagation();
        const noti = this.notification;
        if (noti) {
            this.fadeout = true;
            serverService.wait(250).then(() => {
                serverService.messaging.markNotificationRead(noti);
            });
        }
    }
}
